/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import phonyloopsLogo from "../assets/phonyloops-logo.png";

export default function Footer() {
    return (
        <footer>
            <section className="uk-section uk-background-primary">
                <div className="uk-container">
                    <div className="uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
                        <div>
                            <div className="uk-flex uk-flex-column uk-flex-center">
                                <div className="logo">
                                    <img src={logo} alt="logo" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                                </div>
                                <div className="uk-light uk-padding uk-padding-remove-horizontal">
                                    <div>
                                        Kısıklı Mh. Abdipaşa Sk. No:2 D:5 <br/> Üsküdar / İstanbul
                                    </div>
                                    <div className="uk-padding-small uk-padding-remove-horizontal link">
                                        <a href="tel:+902165102218">+90 216 510 2218</a> <br/> <a href="mailto:info@duetinsaat.com.tr" className="uk-link-reset">info@duetinsaat.com.tr</a>
                                    </div>
                                    <div className="uk-padding-small uk-padding-remove-horizontal link">
                                        <a href="https://g.page/duetins?share" target="_blank">Haritada Göster</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-h3 uk-margin-remove uk-text-bold uk-light">Menu</div>
                            <div className="line"/>
                            <ul className="uk-list uk-light link">
                                <li><Link to="/">Anasayfa</Link></li>
                                <li><Link to="/about">Hakkımızda</Link></li>
                                <li><Link to="/quality">Kalite Politikamız</Link></li>
                                <li><Link to="/contact">İletişim</Link></li>
                            </ul>
                        </div>
                        <div>
                            <div className="uk-h3 uk-margin-remove uk-text-bold uk-light">Projeler</div>
                            <div className="line"/>
                            <ul className="uk-list uk-light link">
                                <li><Link to="/continued">Devam Eden Projeler</Link></li>
                                <li><Link to="/doned">Tamamlanan Projeler</Link></li>
                                <li><Link to="/sales">Satışta Olan Projeler</Link></li>
                            </ul>
                        </div>
                        <div>
                            <div className="uk-flex uk-flex-column uk-flex-left">
                                <div className="uk-h3 uk-margin-remove uk-text-bold uk-light">Sosyal Medya</div>
                                <div className="line"/>
                                <div className="link">
                                    <a href="https://www.facebook.com/DuetInsaat" uk-icon="facebook" target="_blank"></a><span className="uk-padding-small uk-padding-remove-left uk-padding-remove-vertical"/>
                                    <span><a href="https://www.instagram.com/duetinsaat/" uk-icon="instagram" target="_blank"></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-xsmall uk-background-primary uk-padding-remove-top">
                <div className="footer-divider uk-visible@m"/>
                {/*Mobile*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-text-center uk-grid-collapse uk-hidden@m" data-uk-grid>
                    <div className="uk-width-1-1 uk-text-small">
                        <div className="uk-light">Copyright 2022 © Bütün hakları saklıdır.</div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <a href="http://www.phonyloops.com"><img src={phonyloopsLogo} alt="phonyloops-logo" data-uk-img/></a>
                    </div>
                </div>
                {/*Desktop*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-grid-collapse uk-flex-middle uk-visible@m" data-uk-grid>
                    <div className="uk-width-expand@m uk-flex uk-flex-column uk-flex-left uk-padding uk-padding-remove-vertical uk-padding-remove-right uk-text-small">
                        <div className="uk-light">Copyright 2022 © Bütün hakları saklıdır.</div>
                    </div>
                    <div className="uk-width-auto@m uk-flex uk-flex-right uk-padding uk-padding-remove-vertical uk-padding-remove-left">
                        <a href="http://www.phonyloops.com"><img src={phonyloopsLogo} alt="phonyloops-logo" className="uk-width-1-1" data-uk-img/></a>
                    </div>
                </div>
            </section>
        </footer>
    )
}

const navbarItems = [
    {
        title: 'ANASAYFA',
        link: '/',
    },
    {
        title: 'HAKKIMIZDA',
        link: '/about',
    },
    {
        title: 'KALİTE POLİTİKAMIZ',
        link: '/quality',
    },
    {
        title: 'DEVAM EDEN PROJELER',
        link: '/continued',
    },
    {
        title: 'TAMAMLANAN PROJELER',
        link: '/doned',
    },
    {
        title: 'İLETİŞİM',
        link: '/contact',
    },
]

export default navbarItems
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

export default function ContactMap() {
    const iframeSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188.1155107411811!2d29.079831738644636!3d41.028577520727474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7eb19de99e7%3A0x69c42b50f37d635a!2zRMOcRVQgTcSwTUFSTElLIMSwTsWeQUFUIExURC4gxZ5UxLAu!5e0!3m2!1str!2str!4v1649517150591!5m2!1str!2str'

    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-margin-auto uk-grid-collapse uk-child-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center">
                        <div className="uk-width-1-1 uk-height-large">
                            <iframe
                            src={iframeSrc}
                            frameborder="0"
                            style={{ border: 0 }}
                            className="uk-width-1-1 uk-height-1-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from 'react-router-dom';
import doneProjectsData from '../../data/projectsDoneData';
import DoneCard from './ProjectsCards/DoneCard';

export default function HomeProjectsDone() {
    return (
        <>
            {/*Mobile*/}
            <section className="uk-section uk-padding-remove-bottom uk-hidden@m">
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-grid-collapse uk-child-width-1-1 grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <Link to="/doned" className="uk-flex uk-flex-center grid-background grid-border uk-link-reset">
                                <div className="uk-h3 uk-light uk-padding uk-text-bold">Tamamlanan Projeler</div>
                            </Link>
                        <div>
                            <div className="uk-position-relative uk-visible-toggle uk-slider uk-slider-container grid-border" tabindex="-1" data-uk-slider>
                                <ul className="uk-slider-items uk-child-width-1-1" data-uk-grid>
                                    {doneProjectsData.map((doneCard) => (
                                    <DoneCard key={doneCard._id} doneCard={doneCard}></DoneCard>
                                    ))}
                                </ul>
                                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Desktop*/}
            <section className="uk-section uk-padding-remove-bottom uk-visible@m ">
                    <div className="uk-container uk-container-xlarge">
                        <div className="uk-grid-collapse uk-width-1-1 grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                                <Link className="uk-width-1-4 uk-flex uk-flex-column uk-flex-center grid-background grid-border uk-link-reset" to="/doned">
                                    <div className="uk-h3 uk-light uk-padding uk-text-bold">Tamamlanan Projeler</div>
                                </Link>
                            <div className="uk-width-3-4">
                                <div className="uk-position-relative uk-visible-toggle uk-slider uk-slider-container grid-border" tabindex="-1" data-uk-slider="sets: true; finite:true;">
                                    <ul className="uk-slider-items uk-grid-match uk-grid-collapse uk-child-width-1-3" data-uk-grid>
                                        {doneProjectsData.map((doneCard) => (
                                        <DoneCard key={doneCard._id} doneCard={doneCard}></DoneCard>
                                        ))}
                                    </ul>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

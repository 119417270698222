/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function DonedProjectsCard(props) {
    const {donedCard} = props;
    return (
        <a href={`#${donedCard.imageUrlTitle}`} data-uk-scroll="offset-top:true;" className="uk-link-reset">
            <li className="grid-border uk-padding-large uk-flex uk-flex-column uk-flex-center" key={donedCard._id}>
                <div className="uk-panel uk-text-center">
                    <div className="uk-text-bold uk-h4 uk-light">{donedCard.header}</div>
                </div>
            </li>
        </a>
    )
}

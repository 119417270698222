/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import continuedProjectsData from '../../data/projectsContinuedData'
import ContinuedProjectsCard from './ContinuedComponents/ContinuedProjectsCard'

export default function ContinuedIntroduction() {
    return (
        <>
            {/*Mobile*/}
            <section className="uk-section uk-padding-remove-bottom uk-hidden@m">
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-grid-collapse uk-child-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="grid-border uk-flex grid-background uk-flex-center">
                            <div className="uk-h3 uk-light uk-padding uk-text-bold">Duet İnşaat <br/> Devam Eden Projeler</div>
                        </div>
                        <div className="grid-border">
                            <div className="uk-position-relative uk-visible-toggle uk-slider uk-slider-container grid-section-background" tabindex="-1" data-uk-slider>
                                <ul className="uk-slider-items uk-child-width-1-1 uk-grid-match" data-uk-grid>
                                    {continuedProjectsData.map((continuedCard) => (
                                    <ContinuedProjectsCard key={continuedCard._id} continuedCard={continuedCard}></ContinuedProjectsCard>
                                    ))}
                                </ul>
                                <a className="uk-position-center-left uk-position-small uk-hidden-hover uk-light" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small uk-hidden-hover uk-light" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Desktop*/}
            <section className="uk-section uk-padding-remove-bottom uk-visible@m ">
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-grid-collapse uk-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="uk-width-1-4 grid-border grid-background uk-flex uk-flex-column uk-flex-center">
                            <div className="uk-h3 uk-light uk-padding uk-text-bold">Duet İnşaat <br/> Devam Eden Projeler</div>
                        </div>
                        <div className="uk-width-3-4 grid-border">
                            <div className="uk-position-relative uk-visible-toggle uk-slider uk-slider-container grid-section-background" tabindex="-1" data-uk-slider="sets: true; finite:true;">
                                <ul className="uk-slider-items uk-grid-match uk-grid-collapse uk-child-width-1-3" data-uk-grid>
                                    {continuedProjectsData.map((continuedCard) => (
                                    <ContinuedProjectsCard key={continuedCard._id} continuedCard={continuedCard}></ContinuedProjectsCard>
                                    ))}
                                </ul>
                                <a className="uk-position-center-left uk-position-small uk-hidden-hover uk-light" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small uk-hidden-hover uk-light" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

export default function ContinuedProjects(props) {
    const {continuedProjectsCard} = props;
    return (
        <>
            <div className="uk-padding uk-padding-remove-horizontal uk-padding-remove-top">
                <div key={continuedProjectsCard._id} className="uk-grid-collapse uk-child-width-1-1 grid-border uk-padding grid-section-background" data-uk-grid id={continuedProjectsCard.imageUrlTitle} data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-padding uk-padding-remove-horizontal uk-light">
                        <div className="uk-h3 uk-text-bold">
                            {continuedProjectsCard.header}
                        </div>
                        {/* <div className="uk-h6">
                            {continuedProjectsCard.description}
                        </div> */}
                    </div>
                    <div>
                        <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider="sets:true;">
                            <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-6@l uk-grid-small grid-section-background" data-uk-grid data-uk-lightbox>
                                {Array.from(Array(continuedProjectsCard.totalImage), (e, i) => {
                                    return <li><a href={`${process.env.PUBLIC_URL}/assets/projects/continued/${continuedProjectsCard.imageUrlTitle}/${i}.jpg`}><img src={`${process.env.PUBLIC_URL}/assets/projects/continued/${continuedProjectsCard.imageUrlTitle}/thumbnail/${i}.jpg`} alt={`${continuedProjectsCard.imageUrlTitle}-${i}`} className="uk-width-1-1 uk-height-1-1" data-uk-img="loading:lazy;" /></a></li>
                                })}
                            </ul>
                            <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                            <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import doneProjectsData from '../../data/projectsDoneData'
import DonedProjects from './DonedComponents/DonedProjects'

export default function DonedAllProjects() {
    return (
        <section className="uk-section">
            <div className="uk-container uk-container-xlarge">
                {doneProjectsData.map((donedProjectsCard) => (
                <DonedProjects key={donedProjectsCard._id} donedProjectsCard={donedProjectsCard}></DonedProjects>
                ))}
            </div>
        </section>
    )
}

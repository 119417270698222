import React from 'react';
import logo from "../assets/splash-logo.gif";

export default function SplashScreen() {
    return (
        <>
            <div className="uk-flex uk-flex-center uk-flex-column uk-flex-middle" uk-height-viewport="true">
                <img src={logo} alt="logo" className="splash-logo" data-uk-img/>
            </div>
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function ContinuedProjectsCard(props) {
    const {continuedCard} = props;
    return (
        <a href={`#${continuedCard.imageUrlTitle}`} data-uk-scroll="offset-top:true;" className="uk-link-reset">
            <li className="grid-border uk-padding-large uk-flex uk-flex-column uk-flex-center" key={continuedCard._id}>
                <div className="uk-panel uk-text-center">
                    <div className="uk-text-bold uk-h4 uk-light">{continuedCard.header}</div>
                </div>
            </li>
        </a>
    )
}

import React from "react";
import certificate1 from "../../assets/policy-certificate-1.jpg";
import certificate2 from "../../assets/policy-certificate-2.jpg";
import certificate3 from "../../assets/policy-certificate-3.jpg";

export default function PolicyCertificate() {
  return (
    <section className="uk-section uk-padding-remove-bottom">
      <div className="uk-container uk-container-xlarge">
        <div className="grid-border uk-margin grid-section-background" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
          <div className="uk-h2 uk-padding uk-light uk-margin-remove uk-text-bold">BELGELERİMİZ</div>
        </div>
        <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-3@m uk-text-center grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
          <div className="grid-border uk-flex uk-flex-last@m uk-width-1-3">
            <img src={certificate1} alt="certificate-1" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-3 uk-padding-small" />
          </div>
          <div className="grid-border uk-flex uk-flex-last@m uk-width-1-3">
            <img src={certificate2} alt="certificate-2" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-3 uk-padding-small" />
          </div>
          <div className="grid-border uk-flex uk-flex-last@m uk-width-1-3">
            <img src={certificate3} alt="certificate-3" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-3 uk-padding-small" />
          </div>
        </div>
      </div>
    </section>
  );
}

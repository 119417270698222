import Navbar from "./components/Navbar";
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomeScreen from "./pages/HomeScreen";
import Footer from "./components/Footer";
import AboutScreen from "./pages/AboutScreen";
import ContactScreen from "./pages/ContactScreen";
import PolicyScreen from "./pages/PolicyScreen";
import ContinuedScreen from "./pages/ContinuedScreen";
import DonedScreen from "./pages/DonedScreen";
import ToTop from "./components/ToTop";
import SplashScreen from "./components/SplashScreen";
import Sidebar from './components/Sidebar/Sidebar';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      setLoading(true);
      setTimeout(() => {
          setLoading(false);
      }, 2300)
  },[]);

  const [isopen, setisopen] = useState(false)
  const toggle = () => {
    setisopen(!isopen)
  }

  return (
    <>
      {
            loading ? 
                <SplashScreen/>
            : 
            <>
              <Navbar toggle={toggle}/>
              <Sidebar isopen={isopen} toggle={toggle} />
              <ScrollToTop/>
                <main className="uk-position-relative" id="main">
                  <ToTop/>
                  <Routes>
                    <Route exact path="/" element={<HomeScreen/>}/>
                    <Route path="/about" element={<AboutScreen/>}/>
                    <Route path="/contact" element={<ContactScreen/>}/>
                    <Route path="/quality" element={<PolicyScreen/>}/>
                    <Route path="/continued" element={<ContinuedScreen/>}/>
                    <Route path="/doned" element={<DonedScreen/>}/>
                  </Routes>
                </main>
              <Footer/>
            </>
        }
    </>
  );
}

export default App;

/* eslint-disable no-undef */
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_r55bjyx', 'template_mxnp6p8', form.current, '0dO2gawWebBe0hpZ-')
        .then((result) => {
            console.log(result.text);
            UIkit.notification("<span uk-icon='icon: check'></span> Mesajınız başarı ile gönderildi.",{status:'success'});
        }, (error) => {
            console.log(error.text);
            UIkit.notification("<span uk-icon='icon: warning'></span> Mesajınız gönderilemedi.",{status:'danger'});
        });
        e.target.reset();
    };
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-child-width-1-1 grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-center">
                        <div className="uk-padding uk-h3 uk-light uk-text-bold">İLETİŞİM FORMU</div>
                    </div>
                    <div className="grid-border uk-flex uk-flex-center">
                        <div className="uk-width-1-2@m ">
                            <form className="uk-grid-small uk-flex-center uk-margin-auto uk-width-1-2@m uk-padding uk-padding-remove-horizontal uk-padding-remove-bottom" data-uk-grid ref={form} onSubmit={sendEmail}>
                                <div className="uk-width-1-1@s uk-padding-small uk-padding-remove-vertical">
                                    <label className="uk-form-label" htmlFor="name">Ad Soyad*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                                        <input className="uk-input" type="text" placeholder="Adınızı soyadınızı giriniz" id="name" name="name" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="email">Email*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: mail"></span>
                                        <input className="uk-input" type="email" placeholder="Email adresinizi giriniz" id="email" name="email" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="subject">Subject*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: quote-right"></span>
                                        <input className="uk-input" type="text" placeholder="Konunuzu giriniz" id="subject" name="subject" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small uk-padding-remove-vertical">
                                    <label className="uk-form-label" htmlFor="message">Mesajınız*</label>
                                    <textarea className="uk-textarea resize-none" rows="5" placeholder="Mesajınızı giriniz" id="message" name="message" autoComplete="off" required></textarea>
                                </div>
                                <div className="uk-width-1-1@s uk-flex-center uk-flex uk-padding">
                                    <button className="uk-button uk-button-primary" type="submit">Gönder</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from 'react'
import HomeReferance from '../components/Home/HomeReferance'
import AboutContent from '../components/About/AboutContent'
import AboutBanner from '../components/About/AboutBanner'
import AboutMission from '../components/About/AboutMission'
import AboutPolicy from '../components/About/AboutPolicy'

export default function QualityScreen() {
    return (
        <>
            <AboutBanner/>
            <AboutPolicy/>
            <AboutContent/>
            <AboutMission/>
            <HomeReferance/>
        </>
    )
}

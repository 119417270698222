/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

export default function ToTop() {
    return (
        <section className="uk-position-absolute uk-position-fixed uk-flex uk-flex-column uk-flex-right uk-visible@s uk-position-right" data-uk-height-viewport>
            <a href="#" data-uk-icon="icon: chevron-up; ratio: 1.25" data-uk-scroll className="uk-light uk-padding-small"></a>
        </section>
    )
}

import React from 'react'
import nature from "../../assets/policy-nature.jpg";

export default function PolicyNature() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last uk-flex-first@m">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-margin-small uk-light uk-text-bold">ÇEVRE POLİTİKAMIZ</div>
                            <p className="uk-margin-small uk-light">İlgili tüm yasa ve yönetmeliklerle belirlenen standartları sağlamak.</p>
                            <p className="uk-margin-small uk-light">Doğal kaynak kullanımını azaltmak ve mümkün olduğunca tekrar kullanılabilir ve geri dönüştürülebilir materyallerin tüketimine yönelmek.</p>
                            <p className="uk-margin-small uk-light">Hizmet esnasında ve diğer faaliyetlerimizden kaynaklanan atıkları, uygulanabildiği her durumda ve her sahada en aza indirmek.</p>
                            <p className="uk-margin-small uk-light">Yatırım kararları alınırken, çevresel kıstasları dikkate almak.</p>
                            <p className="uk-margin-small uk-light">Tüm faaliyetlerimizde, bunların çevreye olabilecek etkileri konusunda tedbirli ve dikkatli davranmak.</p>
                            <p className="uk-margin-small uk-light">Bir çevre yönetim sistemi geliştirip, bu sistemin etkin olmasını ve güncel tutulmasını sağlamak.</p>
                            <p className="uk-margin-small uk-light">Acil Durum Riskleri’ni azaltmak.</p>
                            <p className="uk-margin-small uk-light">Çevrenin korunması ile ilgili olarak tüm personelin eğitilmesini sağlamak.</p>
                            <p className="uk-margin-small uk-light">ISO 14001 ÇEVRE YÖNETİM SİSTEMİ'nin tüm gereklerini yerine getirmek</p>
                            <p className="uk-margin-small uk-light">Acil Durum Riskleri’ni azaltmak.</p>
                            <p className="uk-margin-small uk-light">İşimizle ilgili üçüncü şahıslara, şirketimize mal ve hizmet veren teslimatçılarımıza ve taşeronlarımıza da sistemimize benzer çevresel standartları uygulamaları konusunda tavsiye ve telkinde bulunmak.</p>
                            <p className="uk-margin-small uk-light uk-margin-remove-bottom">Çevre Yönetim Sistemimizi Sürekli İyileştirmek, çevrede yol açtığımız kirlenmeyi önlemek.</p>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first uk-flex-last@m">
                        <img src={nature} alt="Nature" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from 'react'

export default function PolicyBanner(props) {
    return (
        <section class="uk-section uk-section-large uk-background-cover uk-background-fixed uk-cover-container uk-position-relative quality-banner">
            <div className="uk-container">
                <div className="uk-position-bottom-left uk-position-small uk-text-center uk-light" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-margin banner-text uk-text-bold">Kalite Politikamız</div>
                </div>
            </div>
        </section>
    )
}

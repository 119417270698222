import React from 'react'
import about from "../../assets/homeabout.jpg";
import {Link} from "react-router-dom";

export default function HomeAbout() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-margin-auto uk-grid-collapse uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border">
                        <img src={about} alt="about_image" className="uk-width-1-1 uk-height-1-1" data-uk-img="loading:lazy;" />
                    </div>
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-text-uppercase uk-margin-small uk-light uk-text-bold">Hakkımızda</div>
                            <div className="uk-h5 uk-margin-small uk-light">Hedefimiz, hem yurt içinde hem de yurt dışında çağın gereklerine uygun, modern, sağlam ve kalıcı eserlere imza atmaktır.</div>
                            <p className="uk-light uk-margin-remove">1996 yılında kurulan firmamız anahtar teslim, taahhüt, ve kendi konut projelerini geliştirmektedir. Faliyetlerinde sanayi, altyapı ve konut projelerini başarıyla gerçekleştirmiştir. Sürekli değişen ve yenilenen inşaat sektöründe, teknolojik bilgi birikimimiz, güçlü sermaye yapımız, modern mimari anlayışımız, deneyimli teknik ve idari kadromuzla birlikte nitelikli projelere imza atmak temel prensibimizdir...</p>
                            <p className="uk-margin uk-light">Ürettiğimiz her işte planlama, organizasyon ve proje aşamasına maksimum özeni göstermekteyiz.Mimarlarımızdan mühendislerimize ve pazarlama ekibimize herkesin insana değer veren bir bakış açısıyla kaliteye odaklanması ve işini severek yapması bizleri bir araya getiren ortak değerlerdir.</p>
                            <Link className="uk-button uk-button-primary" to="/about">Devamını Oku</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from 'react'
import contentAbout from "../../assets/about-content.jpg";

export default function AboutContent() {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center uk-flex-last uk-flex-first@m">
                        <div className="uk-padding">
                            <div className="uk-h2 uk-text-uppercase uk-margin-small uk-light uk-text-bold">Hakkımızda</div>
                            <p className="uk-margin-small uk-light">1996 yılında kurulan firmamız anahtar teslim, taahhüt, ve kendi konut projelerini geliştirmektedir. Faliyetlerinde sanayi, altyapı ve konut projelerini başarıyla gerçekleştirmiştir.</p>
                            <p className="uk-margin-small uk-light">Sürekli değişen ve yenilenen inşaat sektöründe, teknolojik bilgi birikimimiz, güçlü sermaye yapımız, modern mimari anlayışımız, deneyimli teknik ve idari kadromuzla birlikte nitelikli projelere imza atmak temel prensibimizdir. Ürettiğimiz her işte planlama, organizasyon ve proje aşamasına maksimum özeni göstermekteyiz.Mimarlarımızdan mühendislerimize ve pazarlama ekibimize herkesin insana değer veren bir bakış açısıyla kaliteye odaklanması ve işini severek yapması bizleri bir araya getiren ortak değerlerdir.</p>
                            <p className="uk-margin-small uk-light">İş üretiminde; çevreye ve doğaya karşı duyarlı, teknolojinin tüm olanaklarını kullanan ve yapı güvenliğine maksimum önem veren bir felsefeye sahibiz.</p>
                            <p className="uk-margin-small uk-light">Yurt içinde gerçekleştirdiğimiz projelerle İstanbul’un değişen ve gelişen mimari anlayışına katkı sağlayan firmalar arasında yer alıyoruz.</p>
                            <p className="uk-margin-small uk-light uk-margin-remove-bottom">Hedefimiz, hem yurt içinde hem de yurt dışında çağın gereklerine uygun, modern, sağlam ve kalıcı eserlere imza atmaktır.</p>
                        </div>
                    </div>
                    <div className="grid-border uk-flex-first uk-flex-last@m">
                        <img src={contentAbout} alt="" data-uk-img="loading:lazy;" className="uk-width-1-1 uk-height-1-1"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

export default function ContactDescripton() {
    return (
        <section className="uk-section uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-container uk-container-xlarge">
                <section className="uk-section">
                    <div className="uk-container uk-container-xlarge">
                        <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center uk-text-center grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: location; ratio: 2" className="uk-light"/>
                                <div className="uk-h4 uk-light uk-margin-small uk-text-bold">Adres</div>
                                <div className="uk-h5 uk-light uk-margin-small link">
                                    <a href="https://g.page/duetins?share" target="_blank">Kısıklı Mh. Abdipaşa Sk. No:2 D:5<br/>Üsküdar / İstanbul</a>
                                </div>
                            </div>
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: mail; ratio: 2" className="uk-light"/>
                                <div className="uk-h4 uk-light uk-margin-small uk-text-bold">E-Mail</div>
                                <div className="uk-h5 uk-light uk-margin-small link"><a className="uk-link-reset" href="mailto:info@duetinsaat.com.tr">info@duetinsaat.com.tr</a></div>
                            </div>
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: phone; ratio: 2" className="uk-light"/>
                                <div className="uk-h4 uk-light uk-margin-small uk-text-bold">Telefon</div>
                                <div className="uk-h5 uk-light uk-margin-small link"><a href="tel:+902165102218" className="uk-link-reset">+90 216 510 2218</a></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

import React from 'react'
import HomeAbout from '../components/Home/HomeAbout';
import HomePolicy from '../components/Home/HomePolicy';
import HomeProjectsContinued from '../components/Home/HomeProjectsContinued';
import HomeProjectsDone from '../components/Home/HomeProjectsDone';
import HomeReferance from '../components/Home/HomeReferance';
import HomeSlider from '../components/Home/HomeBanner';

export default function HomeScreen() {
    return (
        <>
            <HomeSlider/>
            <HomeAbout/>
            <HomePolicy/>
            <HomeProjectsContinued/>
            <HomeProjectsDone/>
            <HomeReferance/>
        </>
    )
}
